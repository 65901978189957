<!--
 * @Author: Jacob.Zhang
 * @Description: 用户个人信息
-->
<template>
    <div class="userinfo-container">
        <div class="info-text">个人信息表</div>
        <div class="explain-text">
            此表由主申请人填写，我们将会根据您的个人信息制作资料清单，提交后1-2个工作日内联系您。
        </div>
        <router-view />
        <transition>
            <div v-show="gotop" class="gotoTop" @click="toTop"></div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'UserInfo',
    data() {
        return {
            gotop: false,
        }
    },
    created() {
        window.addEventListener("scroll", this.handleScroll, true);
    },
    methods: {
        handleScroll() {
            let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
            scrolltop > 200 ? (this.gotop = true) : (this.gotop = false);
        },
        toTop() {
            document.getElementsByTagName('html')[0].scrollIntoView({
                block: "start",
                behavior: "smooth",
            })
        },
    },
}
</script>

<style lang="less" scoped>
.userinfo-container {
    width: 960px;
    margin: 40px auto;
    .info-text {
        font-size: 16px;
        margin-bottom: 8px;
        color: #303133;
        font-weight: bold;
    }
    .explain-text {
        font-size: 14px;
        font-weight: 500;
        color: #606266;
        margin-bottom: 32px;
        letter-spacing: 1px;
    }
}

// gotoTop
.gotoTop {
    position: fixed;
    z-index: 1500;
    bottom: 10px;
    border-radius: 4px;
    right: 10px;
    background: #666f8e url('../../assets/img/goTop.png') 0 0 no-repeat;
    width: 54px;
    height: 54px;
    cursor: pointer;
    opacity: 0.6;
}
.gotoTop:hover {
    opacity: 1;
}
.v-enter {
    opacity: 0;
}
.v-enter-active {
    transition: all 1s;
}
.v-enter-to {
    opacity: 0.6;
}
</style>
